<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import toNumber from 'lodash/toNumber';
import values from 'lodash/values';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiModal,
} from '@emobg/motion-ui/v1';

import { BUTTON_TYPES, Validate } from '@emobg/vue-base';
import {
  cookie as cookieManager,
  snakeCaseKeys,
} from '@emobg/web-utils';
import { PAYOUT_TYPE } from '@emobg/web-api-client';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton, DragFileComponent, LabelWithTooltip, SaveButton,
} from '@/components';
import { isArray } from 'lodash';

export default {
  name: 'PayoutFormComponent',
  components: {
    CancelButton,
    LabelWithTooltip,
    MuiAlgoliaSelect,
    MuiModal,
    SaveButton,
    DragFileComponent,
  },

  directives: {
    Validate,
  },

  props: {
    userUuid: {
      required: true,
      type: String,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isFormValid: false,
      inputs: {
        type: null,
        attachment: null,
        caseEventId: null,
        bookingUuid: null,
        amount: '',
      },
      description: undefined,
      maxTextAreaLength: 150,
      textAreaCharsCount: 150,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      payoutStatus: state => state.newPayout.STATUS,
      payoutData: state => state.newPayout.data,
      payoutError: state => state.newPayout.error,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    payoutTypes() {
      let types = values(PAYOUT_TYPE);

      if (!isEmpty(this.activeOperator) && this.activeOperator.name.toLowerCase() !== 'brunel') {
        types = pull(types, PAYOUT_TYPE.driverReward);
      }

      return types;
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.modalConfig = {
      title: this.$t('CRM.Payouts.create.title'),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };

    this.textAreaCharsCount = this.maxTextAreaLength;

    this.allowedUploadFiletypes = 'image/*, application/pdf';

    this.csrevFeatureFlags = cookieManager.get('csrevFeatureFlags', true) ? JSON.parse(cookieManager.get('csrevFeatureFlags', true)) : null;

    this.isV6Version = this.csrevFeatureFlags ? this.csrevFeatureFlags['CSREV-3688-bo-payout'] : false;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.crm.payouts, [
      'postUserPayout',
      'postCompanyPayout',
      'postPayoutTransaction',
    ]),

    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),

    async savePayout() {
      const convertedInput = snakeCaseKeys(this.inputs);

      const file = this.inputs.attachment;
      const attachmentSent = isArray(file) ? file[0] : file;

      if (this.isFormValid) {
        const payload = {
          uuid: this.userUuid,
          request: {
            ...convertedInput,
            attachment: attachmentSent,
            reason: this.description,
            operator_uuid: this.activeOperator.uuid,
          },
        };

        if (this.isV6Version) {
          const {
            type, caseEventId, bookingUuid, amount,
          } = this.inputs;

          const amountNoDecimals = amount.replace(/[,.`']/g, '.');
          const adjustedAmount = (Number(amountNoDecimals).toFixed(2)) * 100;

          const v6Payload = {
            amount: String(adjustedAmount),
            bookingUuid,
            caseEventId,
            channel: 'web',
            encodedMedia: attachmentSent,
            idempotencyKey: crypto.randomUUID(),
            operatorUuid: this.activeOperator.uuid,
            reason: this.description,
            type,
            userUuid: this.userUuid,
          };

          await this.postPayoutTransaction(v6Payload);
        } else if (this.isCompany) {
          await this.postCompanyPayout(payload);
        } else {
          await this.postUserPayout(payload);
        }

        if (this.payoutStatus.ERROR) {
          const message = get(this.payoutError, 'message');
          const exceptionMessage = get(this.payoutError, 'exception_message');
          this.$throwError({ message: message || exceptionMessage });
        } else {
          this.notify({ message: this.$t('CRM.Payouts.create.successMessage') });
          this.$emit('onSuccess');
          this.closeModal();
        }
      }
    },

    onChangeFile(file) {
      this.inputs.attachment = file;
    },

    incorrectFormatValidator() {
      return {
        isValid: !!this.inputs.amount.match(/^\d+([,.]?\d{1,2})?$/),
        message: this.$t('CRM.Payouts.create.ibanFormatError'),
      };
    },

    maximumAmountValidator(value) {
      return {
        isValid: toNumber(value.replace(',', '.')) <= 500,
        message: this.$t('CRM.Payouts.create.ibanAmountError'),
      };
    },

    closeModal() {
      this.clearData();

      this.$emit('input', false);
    },

    onSelectPayoutType(value) {
      this.inputs.type = this.payoutTypes[value.detail];
    },

    isFormAllValid(value) {
      const textAreaLenghtIsValid = this.textAreaCharsCount > 0;

      this.isFormValid = value.detail.isValid && value.detail.areAllValidated && textAreaLenghtIsValid;
    },

    clearData() {
      this.inputs = {
        type: null,
        attachment: null,
        caseEventId: null,
        bookingUuid: null,
        amount: '',
      };

      this.amount = undefined;
      this.description = undefined;
      this.textAreaCharsCount = this.maxTextAreaLength;
    },

    textAreaManager(text) {
      if (text.detail.length >= this.maxTextAreaLength) {
        this.textAreaCharsCount = 0;
        return;
      }

      this.description = text.detail;
      this.textAreaCharsCount = this.maxTextAreaLength - text.detail.length;
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    :is-open="value"
    @modal-closed="closeModal"
  >
    <div slot="body">
      <ui-validate
        class="d-flex flex-wrap mx-3"
        @status="isFormAllValid"
      >
        <ui-select
          v-validate.select="{
            isRequired: true,
          }"
          :value.prop="inputs.type"
          :options.prop="payoutTypes.map((payout, index) => ({
            value: index,
            label: payout,
          }))"
          :label="`${$t('CRM.Payouts.create.typeLabel')}*`"
          :placeholder="$t('CRM.Payouts.create.typePlaceholder')"
          required
          name="type"
          class="w-100 mb-2"
          @selectoption="onSelectPayoutType"
        />

        <ui-text-area
          v-validate.input="{
            isRequired: true,
          }"
          :value="description"
          :label="`${$t('CRM.Payouts.create.reasonLabel')}* (${textAreaCharsCount} characters left)`"
          :placeholder="$t('CRM.Payouts.create.reasonPlaceholder')"
          class="w-100 my-2"
          name="description"
          :maxlength="maxTextAreaLength"
          @changevalue="textAreaManager"
        />

        <div class="w-100 my-2">
          <LabelWithTooltip
            :label="`${$t('CRM.Payouts.create.relatedBookingsLabel')}*`"
            :tooltip="$t('CRM.Payouts.create.relatedBookingsTooltip')"
          />

          <MuiAlgoliaSelect
            v-model="inputs.bookingUuid"
            v-validate.select="{
              isRequired: true,
            }"
            :filters="`user_uuid:${userUuid}`"
            :title="({ id, vehicle_brand, vehicle_model }) =>
              `${id} - ${vehicle_brand} ${vehicle_model}`"
            :placeholder="$t('CRM.Payouts.create.relatedBookingsPlaceholder')"
            :index="ALGOLIA_INDEXES.csBookings"
            class="w-100"
            name="booking"
            path-value="uuid"
          />
        </div>

        <div class="w-100 my-2">
          <LabelWithTooltip
            :label="$t('CRM.Payouts.create.attachmentLabel')"
            :tooltip="$t('CRM.Payouts.create.attachmentTooltip')"
          />

          <DragFileComponent
            v-model="inputs.attachment"
            :accepted-formats="allowedUploadFiletypes"
            :draggable-height="100"
            :multiple="false"
            data-test-id="file-input"
            @change="onChangeFile"
          />
        </div>

        <div class="w-100 my-2">
          <LabelWithTooltip
            :label="$t('CRM.Payouts.create.relatedCaseLabel')"
            :tooltip="$t('CRM.Payouts.create.relatedCaseTooltip')"
          />

          <MuiAlgoliaSelect
            v-model="inputs.caseEventId"
            :filters="getOperatorFilter({ attribute: 'cs_operator_id' })"
            :title="(r) => `${r.id} - ${r.case_type_name_translations.en_GB}`"
            :placeholder="$t('CRM.Payouts.create.relatedCasePlaceholder')"
            :index="ALGOLIA_INDEXES.caseEvents"
            class="w-100"
            name="case_event_id"
            path-value="id"
          />
        </div>

        <ui-text-input
          v-validate.input="{
            incorrectFormatValidator,
            maximumAmountValidator,
            isRequired: true,
          }"
          :value="inputs.amount"
          :type="INPUT_TYPES.number"
          :label="`${$t('CRM.Payouts.create.amountLabel')}*`"
          :placeholder="$t('CRM.Payouts.create.amountLabel')"
          class="w-100 mt-2"
          name="amount"
          @changevalue="({ detail }) => inputs.amount = detail"
        />

        <span class="font-s">{{ $t('CRM.Payouts.create.amountTooltip') }}</span>
      </ui-validate>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end p-3">
        <CancelButton @click="closeModal" />
        <SaveButton
          :loading="payoutStatus.LOADING"
          :disabled="!isFormValid"
          class="mr-2"
          @click="savePayout"
        />
      </div>
    </template>
  </MuiModal>
</template>
